import Vue from 'vue'
import Vuex from 'vuex'
import WebSocketService from '@/utils/WebSocketService'

Vue.use(Vuex)
const websocketUrl = ''
const store = new Vuex.Store({
    state: {
        openMessageUserId: null,
        reconnectAttempts: 0, // 当前重连尝试次数
        maxReconnectAttempts: 50, // 最大重连尝试次数
        socket: null,
        isShowLoading: false, // 全局 loading
        currentUser: null, // 添加当前用户信息属性
        // 左侧菜单栏数据
        menuItems: [
            {
                name: 'home', // 要跳转的路由名称 不是路径
                size: 18, // icon大小
                type: 'md-home', // icon类型
                text: '主页', // 文本内容
            },
            {
                name: 'userHome', // 要跳转的路由名称 不是路径
                size: 18, // icon大小
                type: 'ios-egg-outline', // icon类型
                text: '用户主页', // 点击侧边栏跳到一个单独的路由页面，需要提前在 router.js 定义
            },
            {
                size: 18, // icon大小
                type: 'md-arrow-forward', // icon类型
                text: '外链',
                url: 'https://www.baidu.com',
                isExternal: true, // 外链 跳到一个外部的 URL 页面
            },
            {
                text: '二级菜单',
                type: 'ios-paper',
                children: [
                    {
                        type: 'ios-grid',
                        name: 't1',
                        text: '表格',
                        // hidden 属性 隐藏此菜单 可以通过在地址栏上输入对应的 URL 来显示页面
                        // hidden: true,
                    },
                    {
                        size: 18, // icon大小
                        type: 'md-arrow-forward', // icon类型
                        text: '外链',
                        url: 'https://www.x6o.com/articles/9498',
                        isExternal: true, // 外链 跳到一个外部的 URL 页面
                    },
                    {
                        text: '三级菜单',
                        type: 'ios-paper',
                        children: [
                            {
                                type: 'ios-notifications-outline',
                                name: 'msg',
                                text: '查看消息',
                            },
                            {
                                type: 'md-lock',
                                name: 'password',
                                text: '修改密码',
                            },
                            {
                                type: 'md-person',
                                name: 'userinfo',
                                text: '基本资料',
                            },
                            {
                                size: 18, // icon大小
                                type: 'md-arrow-forward', // icon类型
                                text: '壁纸网站',
                                url: 'https://wallhaven.cc',
                                isExternal: true, // 外链 跳到一个外部的 URL 页面
                            },
                        ],
                    },
                ],
            },
            {
                text: '系统管理',
                type: 'ios-paper',
                children: [
                    {
                        type: 'ios-grid',
                        name: 'logRecord',
                        text: '业务日志',
                        // hidden 属性 隐藏此菜单 可以通过在地址栏上输入对应的 URL 来显示页面
                        // hidden: true,
                    },
                    {
                        type: 'ios-grid',
                        name: 'userList',
                        text: '用户列表',
                    },
                    {
                        type: 'ios-grid',
                        name: 'scanLoginLog',
                        text: '扫码登录日志',
                    },
                    {
                        type: 'ios-grid',
                        name: 'DynamicTask',
                        text: '定时任务管理',
                    },
                ],
            },
        ],
    },
    mutations: {
        setOpenMessageUserId(state, id) {
            state.openMessageUserId = id;
          },
        setCurrentUser(state, user) {
            state.currentUser = user
        },
        setMenus(state, items) {
            state.menuItems = [...items]
        },
        setLoading(state, isShowLoading) {
            state.isShowLoading = isShowLoading
        },
        setSocket(state, socket) {
            state.socket = socket
        },
        incrementReconnectAttempts(state) {
            state.reconnectAttempts++
        },
        resetReconnectAttempts(state) {
            state.reconnectAttempts = 0
        },
    },
    actions: {
        updateCurrentUser({ commit }, user) {
            commit('setCurrentUser', user)
        },
        initializeWebSocket({ commit, dispatch }, url) {
            const webSocketService = new WebSocketService(url)
            commit('setSocket', webSocketService)

            webSocketService.addListener('open', (event) => {
                commit('resetReconnectAttempts') // 连接成功时重置尝试次数
            })
            webSocketService.addListener('close', (event) => {
                console.log('WebSocket connection closed:', event)
                dispatch('handleWebSocketClose', url)
            })
            webSocketService.addListener('error', (event) => {
                console.log('WebSocket encountered an error:', event)
                dispatch('handleWebSocketClose', url)
            })
        },
        handleWebSocketClose({ state, dispatch }, url) {
            if (state.reconnectAttempts < state.maxReconnectAttempts) {
                setTimeout(() => {
                    dispatch('reconnect', url)
                }, 5000)
            } else {
                console.log('Reached maximum reconnect attempts.')
            }
        },

        reconnect({ commit, state, dispatch }, url) {
            if (state.socket.isConnected()) {
                console.log('WebSocket is already connected. No need to reconnect.')
                return
            }

            if (state.reconnectAttempts < state.maxReconnectAttempts) {
                console.log(`Reconnect attempt ${state.reconnectAttempts + 1}`)
                const webSocketService = new WebSocketService(url)
                commit('setSocket', webSocketService)
                commit('incrementReconnectAttempts')

                webSocketService.addListener('open', (event) => {
                    // 使用事件总线发送事件
                    Vue.prototype.$eventBus.$emit('websocket-reconnected')
                    console.log('WebSocket reconnected:', event)
                    commit('resetReconnectAttempts')
                })
                webSocketService.addListener('close', (event) => {
                    console.log('WebSocket reconnection closed:', event)
                    dispatch('handleWebSocketClose', url)
                })
                webSocketService.addListener('error', (event) => {
                    console.log('WebSocket reconnection encountered an error:', event)
                    dispatch('handleWebSocketClose', url)
                })
            } else {
                console.log('No more reconnect attempts will be made.')
            }
        },
    },
})

export default store